import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, LeftOutlined } from '@ant-design/icons';

import {Steps, Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, message } from 'antd';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { AllHtmlEntities } from 'html-entities';

//import SelectMesos from '../../../SelectMesos';
import { Api, HtmlStrings } from '../../../../helpers';

const entities = new AllHtmlEntities();


function FacturacioCrea(props){


  const [id, setId] = useState(0);
  const [id_curs_escolar, setId_curs_escolar] = useState(props.id_curs_escolar || 0);
  const [id_pressupost, setId_pressupost] = useState(props.id_pressupost || 0);


  const [pas, setPas] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [passosVisibles, setPassosVisibles] = useState(false);


  useEffect(() => {
    setId_curs_escolar(props.id_curs_escolar);
    console.log('useEffect id_curs_escolar', props.id_curs_escolar)
  },[props.id_curs_escolar]);

  useEffect(() => {
    setId_pressupost(props.id_pressupost);
  },[props.id_pressupost]);


  const submitForm = async (values) =>{

    let correcte = true;
    console.log('submitForm id_curs_escolar', id_curs_escolar)
    console.log('submitForm id_pressupost', id_pressupost)

    if(id_curs_escolar<=0){
      correcte = false;
      console.log(props.id_curs_escolar)
      message.config({top: 50});
      message.error({content: 'Curs escolar INCORRECTE', duration:10});
    }
    if(id_pressupost<=0){
      correcte = false;
      console.log(props.id_pressupost)
      message.config({top: 50});
      message.error({content: 'ID pressupost INCORRECTE', duration:10});
    }

    if(!correcte){return false}

      setIsLoading(true)

      try{

        await pasosCrearPressupost();
        setIsLoading(false);

      }catch(error){
        setIsLoading(false);
        message.config({top: 50});
        message.open({content: "Error facturacio", duration:10});
        console.error('Error: FacturacioToRebuts guardar: ', error);
      }

  }

  const pasosCrearPressupost = async() =>{
    setPassosVisibles(true);

    try{

      //PAS 0 - INSERT BOTIGA
//Ens saltem el pas per a la facturació de setembre 2024
let resposta;
setPas(1);
/*
      let resposta = await Api.myFetch('/pressupost/botiga', 'POST', JSON.stringify({id_pressupost: id_pressupost}));
      if(resposta.result.success === 1){
        setPas(1);
      }else{
        setIsLoading(false);
        console.error('Error: submit FacturacioToRebuts Botiga: ',resposta);
        message.error({content: resposta.data.message, top: 50, duration:10});
        return false;
      }
*/

    //PAS 1 - INSERT ACOLLIDA MINUTS
      resposta = await Api.myFetch('/acollidaminuts/topressupost', 'POST', JSON.stringify({id_pressupost: id_pressupost, id_curs_escolar: id_curs_escolar}));
      if(resposta.result.success === 1){
        setPas(2);
      }else{
        setIsLoading(false);
        console.error('Error: submit FacturacioToRebuts AcollidaMinuts: ',resposta);
        message.error({content: resposta.data.message, top: 50, duration:10});
        return false;
      }
    //PAS 2 - GENERA REBUTS
      resposta = await Api.myFetch(`/pressupost/genera/rebuts/${id_pressupost}`, 'POST');
      if(resposta.result.success === 1){
        setPas(3);
        message.success(props.t('generic.OK_saved'), 5);
      }else{
        setIsLoading(false);
        console.error('Error: submit FacturacioToRebuts Rebuts: ',resposta);
        message.error({content: resposta.data.message, top: 50, duration:10});
        return false;
      }


    }catch(error){
      setIsLoading(false);
      message.config({top: 50});
      message.error({content: "Error saving", duration:10});
      console.error('Error: FacturacioToRebuts guardar: ', error);
    }
  }


  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }

  const dateFormat = 'DD/MM/YYYY';

  return(
    <>
    <Form onFinish={submitForm}
      layout="vertical"
      colon={true}
      autoComplete="off"
    >

    <Row justify="center">
      <Col>
        <Steps direction="vertical" current={pas}>
          <Steps.Step title="Botiga" description="Insert Botiga" />
          <Steps.Step title="Acollida" description="Insert Acollida" />
          <Steps.Step title="Rebuts" description="Generar Rebuts" />
          <Steps.Step title="Finalitzat" description="Procés finalitzat correctament." />
        </Steps>
      </Col>
    </Row>


  <Row justify="center" style={{margin: '1rem'}}>
  { pas === 3 ?
    <Col>
      <Button onClick={()=>props.callback()}>{props.t('generic.back')}</Button>
    </Col>
    :
    <Col>
      <Button type="primary" htmlType="submit" loading={isLoading} disabled={pas!==0}>{props.t('facturacio.facturar_pressupost')}</Button>
    </Col>
  }
  </Row>
</Form>
    </>
  );
}
export default translate(FacturacioCrea);
